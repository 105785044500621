<template>
  <v-container class="fill-height background-pattern pa-0" fluid>
    <!--  Navbar  -->
    <u-base-nav />

    <v-row class="mx-8" justify="center">
      <!--  New Password Form  -->
      <v-col cols="12" lg="4" md="6" sm="10" xl="3">
        <v-card
          class="pa-8 fill-height d-flex flex-column justify-center"
          elevation="0"
          rounded="lg"
        >
          <v-container fluid>
            <h4 class="text-h4 font-weight-bold mb-8">Set new password</h4>

            <v-form ref="resetPasswordForm" @submit.prevent="resetPassword">
              <u-text-field
                ref="newPassword"
                v-model="newPassword"
                :rules="[rules.required, rules.password]"
                class="my-4"
                large
                placeholder="New password"
                type="password"
              />
              <u-text-field
                ref="confirmPassword"
                v-model="confirmPassword"
                :rules="[
                  rules.required,
                  rules.password,
                  rules.passwordConfirm(newPassword),
                ]"
                class="my-4"
                large
                placeholder="Confirm new password"
                type="password"
              />
            </v-form>

            <v-alert
              v-if="error"
              class="text-center"
              color="accent"
              text
              type="error"
            >
              <strong>
                {{ errorMessage }}
              </strong>
            </v-alert>

            <u-button
              block
              class="text-capitalize font-weight-bold"
              color="secondary"
              large
              @click="resetPassword"
            >
              Reset
            </u-button>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { UBaseNav, UButton, UTextField } from "@/components/base";
import { rules } from "@/utils/formValidation";
import ApiService from "@/services/ApiService";

export default {
  name: "ResetPassword",
  components: { UBaseNav, UTextField, UButton },
  data() {
    return {
      newPassword: "",
      confirmPassword: "",

      rules,

      error: false,
      errorMessage: "",
    };
  },
  methods: {
    async resetPassword() {
      this.error = false;

      if (!this.$refs.resetPasswordForm.validate()) return;

      const params = {
        token: this.$route.query.token,
        new_password: this.newPassword,
      };
      try {
        await ApiService.resetPassword(params);
        this.$router.push({ name: "Login" }).then(() => {});
      } catch (err) {
        console.log(err);
        this.error = true;
        this.errorMessage = err.response.data?.message ?? err.response.data;
      }
    },
  },
};
</script>

<style scoped>
.background-pattern {
  background: url("../assets/gray-pattern.svg") no-repeat;
  background-size: cover;
}
</style>
