import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height background-pattern pa-0",attrs:{"fluid":""}},[_c('u-base-nav'),_c(VRow,{staticClass:"mx-8",attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"4","md":"6","sm":"10","xl":"3"}},[_c(VCard,{staticClass:"pa-8 fill-height d-flex flex-column justify-center",attrs:{"elevation":"0","rounded":"lg"}},[_c(VContainer,{attrs:{"fluid":""}},[_c('h4',{staticClass:"text-h4 font-weight-bold mb-8"},[_vm._v("Set new password")]),_c(VForm,{ref:"resetPasswordForm",on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}}},[_c('u-text-field',{ref:"newPassword",staticClass:"my-4",attrs:{"rules":[_vm.rules.required, _vm.rules.password],"large":"","placeholder":"New password","type":"password"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('u-text-field',{ref:"confirmPassword",staticClass:"my-4",attrs:{"rules":[
                _vm.rules.required,
                _vm.rules.password,
                _vm.rules.passwordConfirm(_vm.newPassword) ],"large":"","placeholder":"Confirm new password","type":"password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),(_vm.error)?_c(VAlert,{staticClass:"text-center",attrs:{"color":"accent","text":"","type":"error"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]):_vm._e(),_c('u-button',{staticClass:"text-capitalize font-weight-bold",attrs:{"block":"","color":"secondary","large":""},on:{"click":_vm.resetPassword}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }